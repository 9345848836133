import React from 'react'
import { Form,Col } from 'react-bootstrap'
//import Row from "react-bootstrap/row"
//import Container from "react-bootstrap/container"
import { Row, Container } from 'react-bootstrap';
import Button from "react-bootstrap/Button"
import { CSVLink } from "react-csv";

import { useState, useEffect} from "react";
import axios from "axios";
import { useParams } from 'react-router'
import { parse } from 'papaparse';
import VCard from "vcard-creator";
// import VCards from "vcards-js"





const headers = [
  {label:'First Name',key:'first_name'},
  {label:'Middle Name',key:'middle_name'},
  {label:'Last Name',key:'last_name'},
  {label:'User Name',key:'user_name'},
  {label:'Nick Name',key:'nick_name'},
  {label:'User Email',key:'emailAddress'},
  {label:'Phone Number',key:'phoneNumber'},
  
  {label:'Date of Birth',key:'dob'},
  {label:'Location',key:'locAddress'},
  {label:'Job Title',key:'jobDetails_test'},
  {label:'Company',key:''},
  // {label:'Notes',key:'voice_note'},
  {label:'Social Media URL',key:'socialMedia'},
  {label:'Fax',key:'fax'},
  {label:'Website',key:'webAddress'}

]









const FormPage = () => {
  
  
  const [userData, setUserData] = useState({})
  const [userMobile,setUserMobile] = useState([])
  const [userPrimarymob, setUserprimaryMob] = useState('')
  const [userMail,setUserMail] = useState([])
  const [userLocation,setUserLocation] = useState([])
  const [userJob,setUserJob] = useState([]);
  const [userWebsite,setUserWebsite] = useState([])
  const [userMedia,setUserMedia] = useState([])
  const [isDownloading, setIsDownloading] = useState(false);

  





  

  // console.log(userData)
  const { id } = useParams();
  const myVCard = new VCard();
  
  console.log(userMobile)
  console.log(userData)

 
  
  
  

  

  
  
    
    const userphneRender = userMobile?.map((phoneNumber, index) => (
    
    
    <Col
      md
      key={index}>
      
      {/* <p style={{color:'#1A6EE2',paddingTop:'18%'}}>{phoneNumber.type}</p> */}
      
      <span className="input-key">{phoneNumber.type}</span>
      <Form.Control
        type='text'
        placeholder=''
        className='input-txt'
        value={phoneNumber.number} disabled
       
      />
      
    </Col>
    
  ));
  const usermailRender = userMail?.map((emailAddress,index) => (
    <Col md key={index}>
      <span className="input-key">{emailAddress.type}</span>
      <Form.Control type='text'placeholder=''className='input-txt' value={emailAddress.mailid} disabled
      />
    </Col>
  ))
  const firstName = userData?.first_name;
  const middleName = userData?.middle_name;
  const lastName = userData?.last_name;
  const userName = userData?.user_name;
  const nickName = userData?.nick_name;
  const fax = userData?.fax;
  // const userEmail = userData?.user_email;
  const dob = userData?.dob;
  const userWebsiteNumbers = () => {
    userMobile?.forEach((phoneNumber) => {
      myVCard.addPhoneNumber(
        phoneNumber.number,
        phoneNumber.type.toUpperCase()
      );
    });
  
    userLocation?.forEach((locAddress) => {
      myVCard.addAddress(
        "",
        "",
        locAddress.location,
        "",
        "",
        "",
        "",
        locAddress.type
      );
    });
  
    userMail?.forEach((emailAddress) => {
      myVCard.addEmail(emailAddress.mailid, emailAddress.type);
    });
  
    let jobTitleAdded = false; // Flag to track if job title has been added
  
    userJob?.forEach((jobDetails) => {
      // Check if job title has been added
      if (!jobTitleAdded) {
        myVCard.addJobtitle(jobDetails.job_role);
        myVCard.addCompany(jobDetails.company);
        jobTitleAdded = true; // Set the flag to true after adding the job title
      }
    });
  
    userMedia?.forEach((socialMedia) => {
      myVCard.addSocial(socialMedia.social_id, socialMedia.type);
    });
  
    userWebsite?.forEach((userWebsite) => {
      myVCard.addURL(userWebsite.website, userWebsite.type);
    });
  
    console.log(myVCard.toString());
  };
  

  const userWebsiteaddress = userWebsite?.map(
    (userWebsite) => userWebsite.website
  );
  // const userSocialMediaURL = userMedia?.map(
  //   (socialMedia) => userMedia.socialid,userMedia.type
  // )
  myVCard.addName(lastName, firstName, middleName, "");
  myVCard.addPhotoURL(userData?.user_image);
  // myVCard.addCategories({ categories: "location" });

  userWebsiteNumbers();
  console.log(userMail)
  console.log(userLocation)
  console.log(userWebsite)

  const handleDownloadClick1 = () => {
   
    
    
    var blob = new Blob([myVCard.toString()], { type: "text/x-vcard" });
    var downloadUrl = URL.createObjectURL(blob);
// console.log(number1)
    const linkElement = document.createElement("a");
    linkElement.href = downloadUrl;
    linkElement.download = `${firstName} ${lastName}`+ ".vcf";
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
    
      // let number1 = userMobile[0].number;
      // let number2 = userMobile[1].number;

      // let email1 = userMail[0].mailid;
      // let email2 = userMail[1].mailid;

      // let website1 = userWebsite[0].website;
      // let website2 = userWebsite[1].website;

      // let location1 = userLocation[0].location;
      // let location2 = userLocation[1].location;

      // let jobdetails1 = userJob[0].jobname;
      // let jobdetails2 = userJob[1].jobname;


      // let socialmedia1 = userMedia[0].socialid;
      // let socialmedia2 = userMedia[1].socialid;

      // let vcString = `BEGIN:VCARD\nVERSION:3.0\nN:${lastName};
      // ${firstName}\nFN:${lastName}${firstName}\nORG:\n
      
      // TYPE=voice,work,pref:${number1}\nTEL;
      // TYPE=voice,home,pref:${number2}\nTEL;

      // TYPE=voice,work,pref:${email1}\nEMAIL;
      // TYPE=voice,work,pref:${email2}\nEMAIL;

      // TYPE=voice,work,pref:${email1}\nEMAIL;
      // TYPE=voice,work,pref:${email2}\nEMAIL;

      // TYPE=voice,work,pref:${website1}\nURL;
      // TYPE=voice,work,pref:${website2}\nURL;

      // TYPE=voice,home,pref:${location1}\nADR;
      // TYPE=voice,home,pref:${location2}\nADR;

      // TYPE=voice,home,pref:${jobdetails1}\nADR;
      // TYPE=voice,home,pref:${jobdetails2}\nADR;

      // TYPE=voice,home,pref:${socialmedia1}\nURL;
      // TYPE=voice,home,pref:${socialmedia2}\nURL;


      
      // TYPE=intl,work,postal,parcel:;;\n
      
      // END:VCARD`;
      // var blob = new Blob([vcString], { type: "text/x-vcard" });
      // var downloadUrl = URL.createObjectURL(blob);
      // const linkElement = document.createElement("a");
      // linkElement.href = downloadUrl;
      // linkElement.download = `${lastName}` + ".vcf";
      // document.body.appendChild(linkElement);
      // linkElement.click();
      // document.body.removeChild(linkElement);
  }


 


  


  const handleDownloadClick = () => {


   
    const linkElement = document.createElement("a");

    linkElement.setAttribute("href", `data:,${myVCard.toString()}`);
    linkElement.setAttribute("download",firstName + ".vcf");
    linkElement.style.display = "none";
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
   
  };
  // const userMobileNumbers = userMobile?.map(phoneNumber => phoneNumber.number );
  // const userMobileNumbers = userMobile?.map((phoneNumber) => [
  //   phoneNumber.type,
  //   phoneNumber.number,
  // ]);
  //const userMailAddress = userMail?.map(emailAddress => emailAddress.mailid); 
  const userMailAddress = userMail?.map((emailAddress) => [
    emailAddress.type,
    emailAddress.mailid,
  ]);
  //const userLocationaddress = userLocation?.map(locAddress => locAddress.location);
  const userLocationaddress = userLocation?.map((locAddress) => [
    locAddress.type,
    locAddress.location,
  ]);
  //const userJobDetailss = userJob?.map(jobDetails => jobDetails.jobname);
  const userJobDetailss = userJob?.map((jobDetails_test) => [
    jobDetails_test.company,
    jobDetails_test.job_role,
  ]);
  // const userSocialMediaURL = userMedia?.map(socialMedia => socialMedia.socialid);
  const userSocialMediaURL = userMedia?.map((socialMedia) => [
    socialMedia.type,
    socialMedia.social_id,
  ]);
  //const userWebsiteaddress = userWebsite?.map(userWebsite => userWebsite.website)
  // const userWebsiteaddress = userWebsite?.map((userWebsite) => [
  //   userWebsite.type,
  //   userWebsite.website,
  // ]);
  //console.log(userJobDetailss)
  console.log(userWebsiteNumbers)

  const csvReport = {
    filename: 'Report.csv',
    headers: headers,
    
    data: [
      {
        // ... Other user data fields
        first_name:firstName,
        
        last_name:lastName,
        
        nick_name:nickName,
        user_name:userName,
        fax:fax,
        // user_email: userEmail,
        // emailAddress:userMailAddress?.join(', '),
        emailAddress: userMailAddress
          ? [(["type", "mailid"], userMailAddress).join("\n")]
          : null,
        dob:dob,
        // phoneNumber: userMobileNumbers?.join(', '), 
        // phoneNumber: userMobileNumbers
        //   ? [(["type", "number"], userMobileNumbers).join("\n")]
        //   : null,
          locAddress: userLocationaddress
          ? [(["type", "location"], userLocationaddress).join("\n")]
          : null,
          jobDetails_test: userJobDetailss
          ? [(["company", "job_role"], userJobDetailss).join("\n")]
          : null,
          socialMedia: userSocialMediaURL
          ? [(["type", "social_id"], userSocialMediaURL).join("\n")]
          : null,
          webAddress: userWebsiteaddress
          ? [(["type", "website"], userWebsiteaddress).join("\n")]
          : null,
        // locAddress:userLocationaddress?.join(', '),
        // jobDetails:userJobDetailss?.join(', '),
        // socialMedia:userSocialMediaURL?.join(', '),
        // webAddress:userWebsiteaddress?.join(', ')
        
      },
    ],
  }
  
  // //Get multiple Location
  const userLocationAddresses = userLocation?.map((locAddress,index) => (
    <Col md key={index}>
      <span className="input-key">{locAddress.type}</span>
      <Form.Control type='text' placeholder='' className='input-txt' value={locAddress.location} disabled/>

    </Col>
  ))
  //Get Job Details
  const userJobDetails = userJob?.map((jobDetails_test,index) => (
    <Col md key={index}>
      <span className="input-key">{jobDetails_test.company}</span>
      <Form.Control type='text' placeholder='' className='input-txt' value={jobDetails_test.job_role} disabled/>

    </Col>
  ))
  //Get Social Media URL's
  const userSocialMedia = userMedia?.map((socialMedia,index) => (
    <Col md key={index}>
      <span className="input-key">{socialMedia.type}</span>
      <Form.Control type='text' placeholder='' className='input-txt' value={socialMedia.social_id} disabled/>

    </Col>
  ))
  //Get Multiple Website
  const userWebsiteAddress = userWebsite?.map((webAddress,index) => (
    <Col md key={index}>
      <span className="input-key">{webAddress.type}</span>
      <Form.Control type='text' placeholder='' className='input-txt' value={webAddress.website} disabled/>

    </Col>
  ))
  
  console.log(userData);
  // console.log(myVCardVCard.toString());
  console.log(userLocation);
  console.log(userWebsite);
  
  
 

  
  useEffect(() => {
    
    async function init() {
      //Prod URL
      const vcdata = id? await axios.get(`https://vcards.vivyacards.com/vivyacards_app/user_profile/profile_info_web/${id}`):null;
      //const vcdata = id? await axios.get(`https://prod.vivyacards.com/vivyacards_app/user_profile/profile_info_web/${id}`):null;
      //Dev URL
      // const vcdata = id? await axios.get(`http://13.232.9.95:7302/vivyacards_app/user_profile/profile_info_web/${id}`):null;
      //const vcdata = await axios.get("http://13.232.9.95:7302/vivyacards_app/user_profile/profile_info_web/?id=${id}`")
      // console.log(vcdata)
      const data1 = await vcdata?.data.data[0]
      // console.log(data1) 
      setUserData(data1)
      // console.log(userData)

      
      const user_mob = await data1?.phone_number?.mobile
      
     
      // setUserData(user_mob)
     setUserMobile(user_mob)
    //  setUserprimaryMob(user_mob[0])

    const email_addr = await data1?.email?.mail


    setUserMail(email_addr)



     const loc_addr=await data1?.location?.address

     setUserLocation(loc_addr)

     const job_det=await data1?.job_details?.job_title

     setUserJob(job_det)


     const soc_med=await data1?.social_media_urls?.social_id
     
     setUserMedia(soc_med)
     
    const website_addr=await data1?.website?.webUrls
    
    setUserWebsite(website_addr)



    
  }
  init()
  
  },[]);
  
  
  console.log(userJob)

  
  
  
  

  return (
    <Container>
      
      
      <div class="container  mb-4 p-3 d-flex justify-content-center"> 
      <img src="Ellipse 35.png"  className='background' />
      <div class="card p-4" className='profile-container'>
     <div class=" image d-flex flex-column justify-content-center align-items-center"> 
     {/* <button class="btn btn-secondary">  */}
         {/* <img src="Ellipse 31 (1).png" height="100" width="100" /> */}
         <img src={userData?.user_image} height="100" width="100" />
         <span>{userPrimarymob?.number}</span>
         
         <span class="name mt-3" className='profile-name'>{userData?.user_name}</span> 
         
      </div>
      </div>
      </div>
      
        <Row>
            <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Row className="mt-5">
              
                <Col md>
               
               
                <Form.Label className='lbltxt'>First Name</Form.Label>
                <Form.Control type="text" placeholder="Enter first name" className='input-txt'value={userData?.first_name} disabled/>
               
                </Col>
               

                <Col md className='first-col'>
                <Form.Label className='lbltxt' >Middle Name</Form.Label>
                

                <Form.Control type="text" placeholder="Enter Middle Name" className='input-txt'
                value={userData?.middle_name} disabled/>
                
               
                </Col>
                <Col md className='sec-col'>
                <Form.Label className='lbltxt'>Last Name</Form.Label>
                
                <Form.Control type="text" placeholder="Enter Last name"
                value={userData?.last_name} className='input-txt' disabled/>
               </Col>
               <Col md className="third-col">
                
                <Form.Label className='lbltxt' >Nick Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Nick name" value={userData?.nick_name} className='input-txt' disabled/>
               
                </Col>
                </Row>
               
                <Row className="mt-5">
                
                
                <Col md className="inp-title">
               
                <Form.Label className='lbltxt'>Phone Number</Form.Label>
                
                {userMobile ? (
                userphneRender
               ) : (
                <Form.Control type='text' placeholder='Enter your Phone'
                className='input-txt' style={{marginBottom:'10px'}} disabled/>
               )
               }
                </Col>
               
                </Row>
                <Row className='mt-5'>
                <Col md>
                <Form.Label className='lbltxt' >Email</Form.Label>
                
                {userMail? (
                usermailRender
               ) : (
                <Form.Control type='text' placeholder='Enter your Email'
                className='input-txt' style={{marginBottom:'10px'}} disabled/>
               )
               }
                </Col>
                </Row>
                <Row className="mt-5">
               
                <Col md>
                
                <Form.Label className='lbltxt'>Location</Form.Label>
               
              
                {userLocation? (
                userLocationAddresses
               ) : (
                <Form.Control type='text' placeholder='Enter your location'
                className='input-txt' style={{marginBottom:'10px'}} disabled/>
               )
               }
                </Col>
                </Row>
                <Row className='mt-5'>
                <Col md className="web-col">
                
                
                <Form.Label className='lbltxt'>Website</Form.Label>
                
                {userWebsite ? (
                userWebsiteAddress
               ) : (
                <Form.Control type='text' placeholder='Enter your Website'
                className='input-txt' style={{marginBottom:'10px'}} disabled/>
               )
               }
                </Col>
                
               </Row>
               <Row className="mt-5">
               <Col md>
               
               <Form.Label className='lbltxt'>Job Title</Form.Label>
               {userJob? (
               userJobDetails
              ) : (
               <Form.Control type='text' placeholder='Enter your Job Role'
               className='input-txt' style={{marginBottom:'10px'}} disabled/>
              )
              }
              
               </Col>
              </Row>
              <Row className='mt-5'>
               
                <Col md>
              
              <Form.Label className='lbltxt'>Fax</Form.Label>
              <Form.Control type="number" placeholder="Fax" value={userData?.fax} className='input-txt' disabled/>
             
              </Col>
              </Row>
              <Row className='mt-5'>
              <Col md>
               
               <Form.Label className='lbltxt'>Social Media </Form.Label>
               {userMedia? (
               userSocialMedia
              ) : (
               <Form.Control type='text' placeholder='Enter your Web URLs'
               className='input-txt' style={{marginBottom:'10px'}} disabled/>
              )
              }
              </Col>
            
               </Row> 
              
              
             
               
              
            </Form.Group>
           
            <div className='button-container'>
            <Button type="submit"  style={{ marginRight: "10px",backgroundColor:"#8D8D8D",border:"none" }} 
            onClick={() => handleDownloadClick1()}>Download VCF</Button>
           
           {/* <Button type="submit"  style={{ marginRight: "10px",backgroundColor:"#8D8D8D",border:"none" }} 
            onClick={() => handleDownloadClick1()}>Download VCF for IoS</Button>
            */}
      
            </div>
           


            </Form>
        </Row>
        </Container>
    
  )
                    
}


export default FormPage;