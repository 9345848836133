
import './App.css';
import FormPage from './Form';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { Routes, Route } from "react-router-dom";
//Test



function App() {
  
  return (
    // <div className="App">
    
    //  <FormPage />
    // </div>
    <div className='App'>
      <Routes>
        <Route
          path='/'
          Component={FormPage}
        />
        <Route
          path='/:id'
          Component={FormPage}
        />
        
      </Routes>
    </div>
  );
}

export default App;
